<template>
  <div class="t-select">
    <div class="input" @click="active = !active">
      <div class="activator" aria-haspopup="true">
        <span class="placeholder" v-if="inline">{{ placeholder }}</span>
        <span v-if="text">{{ text }}</span>
        <input :placeholder="placeholder" readonly v-if="!text && !inline" />
        <i class="material-icons">expand_more</i>
      </div>
      <ul class="dropdown" aria-label="submenu" :class="{ active }">
        <li
          v-for="(option, index) in options"
          :key="index"
          @click="onChange(option)"
        >
          {{ option[optionText] }}
        </li>
      </ul>
    </div>
    <p class="message" v-if="message">{{ message }}</p>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },

  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
  },

  props: {
    name: String,
    value: Number,
    placeholder: String,
    message: String,
    inline: Boolean,
    optionText: {
      type: String,
      required: true,
    },
    optionValue: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: [],
    },
  },

  data: () => ({
    active: false,
    text: null,
  }),

  watch: {
    options() {
      this.selectDefaultValue();
    },
  },

  methods: {
    onChange(option) {
      this.text = option[this.optionText];
      this.$emit('change', option[this.optionValue]);
    },

    selectDefaultValue() {
      if (this.value && this.value !== -1) {
        const option = this.options.find(x => x[this.optionValue] === this.value);
        if (option) {
          this.text = option[this.optionText];
        }
      }
    },
  },

  created() {
    this.selectDefaultValue();
  },
}
</script>
