import Vue from 'vue';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);

VeeValidate.Validator.extend('is_time', {
  getMessage: () => `The format must be 12 hour.`,
  validate: (value) => new Promise(resolve => {
    const regex = new RegExp('((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))');
    resolve({
      valid: value && regex.test(value),
    });
  })
});

VeeValidate.Validator.extend('verify_password', {
  getMessage: () => `The password must contain at least: 1 uppercase letter, 1 lowercase letter and 1 number.`,
  validate: (value) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"); // eslint-disable-line no-useless-escape
    return strongRegex.test(value);
  }
});
