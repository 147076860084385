<template>
  <button class="e-button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'e-button',
}
</script>
