import * as types from '@/store/types';

export default {
  [types.SET_LAYOUT] (state, layout) {
    state.layout = layout;
  },

  [types.SET_MODE] (state, mode) {
    state.mode = mode;
  },
};
