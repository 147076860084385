<template>
  <div class="t-dropdown" @click="active = !active">
    <div class="activator" aria-haspopup="true">
      {{ value }}
      <i class="material-icons">expand_more</i>
    </div>
    <ul class="dropdown" aria-label="submenu" v-bind:class="{ active }">
      <li v-for="(option, index) in options" v-bind:key="index" @click="$emit('change', option)">
        {{ option }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    options: {
      type: Array,
      default: [],
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data: () => ({
    active: false,
  }),
}
</script>
