
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue';
import App from '@/App';
import store from '@/store';
import router from '@/router';
import firebase from 'firebase/app';

// import "@firebase/firestore";
import '@/bootstrap';
import '@/plugins';
import '@/components';

// firebase.initializeApp({
//   apiKey: 'AIzaSyCB70qkVrSCkbKOGK3QKzKiT101u42rl3Y',
//   databaseURL: 'https://dd-truckerd-in.firebaseio.com',
//   projectId: 'dd-truckerd-in',
//   storageBucket: 'dd-truckerd-in.appspot.com',
//   messagingSenderId: '103524202197',
// });

if (firebase.messaging.isSupported()) {
  firebase.messaging().usePublicVapidKey('BNWXwDk4bI-J7WbpoeafNhuR60J08VouoKRS4Poreog6O-hNn2fXIp366Azc4UqOwte67w0I2paBtGjNtfKRryQ');
}

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.config.productionTip = false;

window.Vue = Vue;
window.eventBus = new Vue();

new Vue({
  el: '#app',
  store,
  router,
  ...App,
});
