import * as types from '@/store/types';
import TokenService from '@/services/TokenService';

export default {
  [types.TOGGLE_SPINNER] (state) {
    state.isLoading = !state.isLoading;
  },

  [types.FETCH_USER_SUCCESS] (state, user) {
    state.user = user;
    TokenService.setUserData(state.user);
  },

  [types.FETCH_USER_FAILURE] (state) {
    state.user = {};
  },

  [types.SAVE_TOKEN] (state, token) {
    state.token = token;
  },

  [types.LOGOUT] (state) {
    state.user = {};
    state.token = null;
  },

  [types.REGISTER_SUCCESS] (state, registered) {
    state.registered = registered;
  },
};
