import * as types from '@/store/types';
import axios from 'axios';
import uuid from 'uuid/v1';
import firebase from 'firebase/app';
import 'firebase/storage';

export default {
  async fetch({ commit }, mode) {
    try {
      commit(types.TOGGLE_SPINNER);
      const { data } = await axios.get('/warehouse/pickups', { params: { mode } });
      commit(types.FETCH_PICKUPS_SUCCESS, data);
    } catch (e) {
      commit(types.FETCH_PICKUPS_FAILURE);
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async checkin({ commit }, pickup) {
    let document;

    try {
      commit(types.TOGGLE_SPINNER);

      if (pickup.bill && !pickup.bill.bill_lading) {
        // Upload document
        document = await firebase.storage()
          .ref(`documents/${uuid()}.pdf`)
          .put(pickup.bill);
      }

      await axios.patch(`/warehouse/pickups/${pickup.id}/checkin`, {
        document: document ? await document.ref.getDownloadURL() : null,
        door_assigned: pickup.door_assigned,
        door_availability: pickup.door_availability,
      });
    } catch (e) {
      if (document) {
        await document.ref.delete();
      }
      throw e;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async updateCheckin({ commit }, {pickup, document, door_assigned}) {
    let file;

    try {
      commit(types.TOGGLE_SPINNER);
      
      if (document && !document.bill_lading) {
        // Upload document
        file = await firebase.storage()
          .ref(`documents/${uuid()}.pdf`)
          .put(document);
      }

      await axios.patch(`/warehouse/pickups/${pickup}/update-checkin`, {
        document: file ? await file.ref.getDownloadURL() : null,
        door_assigned
      });
    } catch (e) {
      if (file) {
        await file.ref.delete();
      }
      throw e;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async rename({ commit }, pickup) {
    try {
      commit(types.TOGGLE_SPINNER);
      await axios.patch(`/pickups/${pickup.id}/rename`, pickup);
    } catch (e) {
      throw e;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async verificateDriver({ commit }, pickup) {
    try {
      commit(types.TOGGLE_SPINNER);
      await axios.patch(`/warehouse/pickups/${pickup.id}/verificateDriver`, pickup);
    } catch (e) {
      throw e;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async verificatePickup({ commit }, pickup) {
    try {
      commit(types.TOGGLE_SPINNER);
      await axios.patch(`/warehouse/pickups/${pickup.id}/verificatePickup`, pickup);
    } catch (e) {
      throw e;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async available({ commit }, pickup) {
    try {
      commit(types.TOGGLE_SPINNER);
      await axios.patch(`/warehouse/pickups/${pickup.id}/available`, pickup);
    } catch (e) {
      throw e;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  toggleMostRecent({ commit }) {
    commit(types.TOGGLE_MOST_RECENT_PICKUP);
  }
};
