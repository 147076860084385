import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

/**
 * Register components
 */
const requireComponent = require.context(
   // The relative path of the components folder
   '.',
   // Whether or not to look in subfolders
   false,
   // The regular expression used to match base component filenames
   /[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
   // Get component config
   const componentConfig = requireComponent(fileName);

   // Get PascalCase name of component
   const componentName = upperFirst(
   camelCase(
      // Strip the leading `./` and extension from the filename
      fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
   )
);

// Register component globally
Vue.component(
   componentName,
     // Look for the component options on `.default`, which will
     // exist if the component was exported with `export default`,
     // otherwise fall back to module's root.
     componentConfig.default || componentConfig
   )
});

/**
 * Register layouts
 */
const requireLayout = require.context(
  // The relative path of the components folder
  './layouts',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /[a-z]\w+\.(vue|js)$/
);

requireLayout.keys().forEach(fileName => {
  // Get layout config
  const layoutConfig = requireLayout(fileName);

  // Get name of layout
  const layoutName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1').toLowerCase() + '-layout';

  // Register layouts globally
  Vue.component(
    layoutName,
    // Look for the layout options on `.default`, which will
    // exist if the layout was exported with `export default`,
    // otherwise fall back to module's root.
    layoutConfig.default || layoutConfig
  )
});
