export const SET_LAYOUT = 'Set Layout';
export const SET_MODE = 'Set Mode';
export const TOGGLE_SPINNER = '⚡️ Toggle Spinner';

// Authentication
export const LOGOUT = '🚪 Logout';
export const SAVE_TOKEN = '🔒 Save Token';
export const FETCH_USER_SUCCESS = '👱✅ Fetch User Successful';
export const FETCH_USER_FAILURE = '👱❌ Fetch User Failed';

// Check In
export const FETCH_PICKUPS_SUCCESS = 'Fetch Pickups Successful';
export const FETCH_PICKUPS_FAILURE = 'Fetch Pickups Failed';
export const TOGGLE_MOST_RECENT_PICKUP = 'Toggle Most Recent Pickup';

// Door
export const FETCH_DOOR_SUCCESS = 'Fetch Door Successful';
export const FETCH_DOOR_FAILURE = 'Fetch Door Failed';
export const TOGGLE_MOST_RECENT_DOOR = 'Toggle Most Recent Door';

// Loaded
export const FETCH_LOADED_SUCCESS = 'Fetch Loaded Successful';
export const FETCH_LOADED_FAILURE = 'Fetch Loaded Failed';

// Unloaded
export const FETCH_UNLOADED_SUCCESS = 'Fetch Unloaded Successful';
export const FETCH_UNLOADED_FAILURE = 'Fetch Unloaded Failed';

//register
export const REGISTER_SUCCESS = 'User registered Successfully';