import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import TokenService from '@/services/TokenService';

axios.defaults.baseURL = '/api';

axios.interceptors.request.use(request => {
  if (TokenService.getToken()) {
    request.headers.common['Authorization'] = `Bearer ${TokenService.getToken()}`;
  }
  return request;
});

axios.interceptors.response.use(
  response => response,
  error => {
    const errorResponse = error.response;

    if (errorResponse.status === 401) {
      store.dispatch('auth/logout');
    }

    return Promise.reject(error);
  }
)

Vue.prototype.axios = axios;
Vue.prototype.$http = axios;

window.axios = axios;
