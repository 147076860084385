<template>
  <div class="t-data-table">
    <div v-if="!isLoading">
      <table v-bind:class="{ 'bold-last-column': boldLastColumn }">
        <thead>
          <tr>
            <th
              v-for="(col, index) in labels"
              v-bind:key="index"
              @click="sortTable(index)"
            >
              {{ col }}
              <div class="arrow" v-if="index === sortColumn" v-bind:class="[ascending ? 'arrow_up' : 'arrow_down']"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in get_rows()" v-bind:key="row.id" @click="$emit('click', row)">
            <td v-for="(col, index) in keys" v-bind:key="index">
              {{ _.get(row, col) }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="paginate">
        <!-- First -->
        <div class="number" v-if="hasFirst" @click="change_page(1)">1</div>
        <div class="number ellipsis" v-if="hasFirstEllipsis">&hellip;</div>

        <!-- Pages -->
        <div
          class="number"
          v-for="i in pagesInRange"
          v-bind:key="i"
          v-bind:class="[i == currentPage ? 'active' : '']"
          @click="change_page(i)">
          {{ i }}
        </div>

        <!-- Last -->
        <div class="number ellipsis" v-if="hasLastEllipsis">&hellip;</div>
        <div class="number" v-if="hasLast" @click="change_page(pageCount)">{{ pageCount }}</div>
      </div>
    </div>
    <div v-else>
      <div class="flex mt-5" v-for="index in 8" :key="index">
        <skeleton-box
          width="15%"
          height="40px"
        />
        <skeleton-box
          class="ml-1"
          width="10%"
          height="40px"
        />
        <skeleton-box
          class="ml-1"
          width="20%"
          height="40px"
        />
        <skeleton-box
          class="ml-1"
          width="20%"
          height="40px"
        />
        <skeleton-box
          class="ml-1"
          width="35%"
          height="40px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 't-data-table',

  data: () => ({
    currentPage: 1,
    ascending: false,
    sortColumn: '',
  }),

  computed:{
    _() {
      return _;
    },

    pageCount() {
      return Math.ceil(this.data.length / this.perPage);
    },

    /**
     * Check if previous button is available.
     */
    hasPrev() {
      return this.currentPage > 1;
    },

    /**
     * Check if first page button should be visible.
     */
    hasFirst() {
      return this.currentPage >= 3;
    },

    /**
     * Check if first ellipsis should be visible.
     */
    hasFirstEllipsis() {
        return this.currentPage >= 4;
    },

    /**
     * Check if last page button should be visible.
     */
    hasLast() {
        return this.currentPage <= this.pageCount - 2;
    },

    /**
     * Check if last ellipsis should be visible.
     */
    hasLastEllipsis() {
        return this.currentPage < this.pageCount - 2 && this.currentPage <= this.pageCount - 3
    },

    /**
     * Get near pages, 1 before and 1 after the current.
     * Also add the click event to the array.
     */
    pagesInRange() {
      const left = Math.max(1, this.currentPage - 1);
      const right = Math.min(this.currentPage + 1, this.pageCount);
      const pages = [];

      for (let i = left; i <= right; i++) {
        pages.push(i);
      }

      return pages;
    },
  },

  props: {
    labels: {
      type: Array,
      required: true,
    },
    keys: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      default: 15,
    },
    paginate: {
      type: Boolean,
      default: true,
    },
    boldLastColumn: Boolean,
    isLoading: Boolean,
  },

  methods: {
    sortTable(index) {
      if (this.sortColumn === index) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.sortColumn = index;
      }

      const col = this.keys[index];

      this.data.sort((a, b) => {
        a = _.get(a, col);
        b = _.get(b, col);

        if (a > b) {
          return this.ascending ? 1 : -1;
        } else if (a < b) {
          return this.ascending ? -1 : 1;
        }

        return 0;
      })
    },

    get_rows() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.data.slice(start, end);
    },

    change_page(page) {
      this.currentPage = page;
    }
  },
}
</script>
