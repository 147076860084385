import * as types from '@/store/types';

export default {
  [types.TOGGLE_SPINNER] (state) {
    state.isLoading = !state.isLoading;
  },

  [types.FETCH_UNLOADED_SUCCESS] (state, unloaded) {
    state.unloaded = unloaded;
  },

  [types.FETCH_UNLOADED_FAILURE] (state) {
    state.unloaded = [];
  },
};
