import * as types from '@/store/types';

export default {
  setLayout({ commit }, layout) {
    commit(types.SET_LAYOUT, layout);
  },

  setMode({ commit }, mode) {
    commit(types.SET_MODE, mode);
  },
};
