import TokenService from '@/services/TokenService';

export default {
  token: state => state.token,
  currentUser: state => {

    if (!state.user || (state.user && Object.keys(state.user).length === 0)) {
      return TokenService.getUserData();
    } else {
      return state.user;
    }

  },
};
