import * as types from '@/store/types';

export default {
  [types.TOGGLE_SPINNER] (state) {
    state.isLoading = !state.isLoading;
  },

  [types.FETCH_DOOR_SUCCESS] (state, door) {
    state.door = door;
    state.allDoor = door;
  },

  [types.FETCH_DOOR_FAILURE] (state) {
    state.door = [];
    state.allDoor = [];
  },

  [types.TOGGLE_MOST_RECENT_DOOR] (state) {
    const all = [...state.allDoor];

    // Toggle showing
    state.showMostRecent = !state.showMostRecent;

    if (state.showMostRecent) {
      state.door = all.splice(0, 1);
    } else {
      state.door = all;
    }
  },
};
