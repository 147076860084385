import * as types from '@/store/types';

export default {
  [types.TOGGLE_SPINNER] (state) {
    state.isLoading = !state.isLoading;
  },

  [types.FETCH_LOADED_SUCCESS] (state, loaded) {
    state.loaded = loaded;
  },

  [types.FETCH_LOADED_FAILURE] (state) {
    state.loaded = [];
  },
};
