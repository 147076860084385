<template>
  <div class="t-input" :class="[$attrs.classes, { small }]">
    <div class="input" v-bind:class="{ bordered }">

      <i class="material-icons" v-if="icon">{{ icon }}</i>
      <span v-if="inline">{{ placeholder }}</span>

      <input
        v-if="type !== 'textarea' && type !== 'stripe_element'"
        ref="inputTag"
        :name="name"
        :value="value"
        :placeholder="inline ? '' : placeholder"
        :type="type"
        :readonly="readonly"
        @input="updateInput($event.target.value)" />

      <input
        v-else-if="type === 'stripe_element'"
        ref="inputTag"
        class="focus:outline-none"
        :value="value"
        v-bind="$attrs"
        :maxlength="maxlength"   
        :placeholder="inline ? '' : placeholder"
        @blur="updateInput($event.target.value)"
        @change="updateInput($event.target.value)"
        v-payment:[stripe_cardformat]
      />

      <textarea
        v-else
        ref="inputTag"
        :name="name"
        :value="value"
        :placeholder="inline ? '' : placeholder"
        :readonly="readonly"
        @input="updateInput($event.target.value)" />
    </div>
    <p class="message" v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import Vue from "vue";
import VueStripePayment from 'vue-stripe-payment';

Vue.use(VueStripePayment);

export default {
  name: 't-input',

  model: {
    prop: 'value',
    event: 'input',
  },

  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
  },

  props: {
    name: String,
    value: String,
    icon: String,
    message: String,
    placeholder: String,
    type: String,
    bordered: Boolean,
    readonly: Boolean,
    small: Boolean,
    inline: Boolean,
    location: Boolean,
    stripe_cardformat: {
      type: String,
      default: null
    },
    maxlength : {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      coordinates: [],
    }
  },

  methods: {
    updateInput(value) {
      if(!this.location) {
        this.$emit('input', value);
      }
    }
  },

  mounted() {
    if (this.location) { 
      this.inputTag = new google.maps.places.Autocomplete(
        (this.$refs.inputTag),
        {types: ['geocode']}
      );

      this.inputTag.addListener('place_changed', () => {
        const place = this.inputTag.getPlace();
        this.coordinates = [this.inputTag.getPlace().geometry.location.lat().toString(), this.inputTag.getPlace().geometry.location.lng().toString()];
        this.$emit('locations', this.coordinates);
        this.$emit('input', place.formatted_address);
      });
    }
  },
}
</script>
