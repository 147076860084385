<template>
  <div class="switch-button">
    <div
      class="option"
      v-for="(option, index) in options"
      v-bind:key="index"
      v-bind:class="{ 'selected': option === selected }"
      @click="updateOption(option)"
    >
      {{ option }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'switch-button',

  data: () => ({
    selected: '',
  }),

  props: {
    default: String,
    options: {
      type: Array,
      required: true,
    },
  },

  created() {
    this.selected = this.default;
  },

  methods: {
    updateOption(value) {
      if (this.selected === value) {
        return;
      }
      this.selected = value;
      this.$emit('changed', value);
    },
  },
}
</script>
