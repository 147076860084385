<template>
  <div
    class="cursor-pointer overflow rounded-full border border-gray-700 w-12 p-px"
    :class="[active ? 'bg-red-200' : 'bg-white']"
    @click="$emit('change', !active)"
   >
    <div
      class="rounded-full w-6 h-6"
      :class="[{ 'ml-5': active }, active ? 'bg-red-500' : 'bg-gray-700']">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
  },
  model: {
    prop: 'active',
    event: 'change',
  },
}
</script>
