<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <i class="material-icons" @click="$emit('close')">close</i>
            <span>{{ title }}</span>
          </div>

          <div class="modal-body overflow-y-auto" :style="{ 'max-height': '600px' }">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modal',
  props: {
    title: String,
  },
}
</script>
