<template>
  <div class="flex">
    <div
      class="flex items-center justify-center shadow border border-gray-600 rounded-full text-xs cursor-pointer w-24 h-8 mx-1"
      v-for="(item, index) in items"
      :key="index"
      :class="{ selected: item === value }"
      @click="$emit('change', item)">
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: String,
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.selected
  @apply bg-red-500 text-white border-none
</style>

