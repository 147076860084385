const Login = () => import('@/views/Auth/Login');
const Plan = () => import('@/views/Auth/Plan');
const Subscribe = () => import('@/views/Auth/Subscribe');
const SubscribeRegistered = () => import('@/views/SubscribeRegisteredUser');
const Register = () => import('@/views/Auth/Register');
const Forgot = () => import('@/views/Auth/Forgot');
const Reset = () => import('@/views/Auth/Reset');
const CheckIn = () => import('@/views/CheckIn');
const Info = () => import('@/views/Info');
const Door = () => import('@/views/Door');
const InfoDoor = () => import('@/views/InfoDoor');
const AuthorizationPIN = () => import('@/views/AuthorizationPIN');
const Information = () => import('@/views/Information');
const Subscription = () => import('@/views/Subscription');
const Notifications = () => import('@/views/Notifications');
const Documents = () => import('@/views/Documents');
const Loaded = () => import('@/views/Loaded');
const Unloaded = () => import('@/views/Unloaded');
const LoadInfo = () => import('@/views/LoadInfo');
const Messages = () => import('@/views/Messages');

export default [
  {
    path: '/',
    alias: '/login',
    name: 'login',
    component: Login,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      isPrimeModule: false
    }
  },
  {
    path: '/plan',
    name: 'plan',
    component: Plan,
    meta: {
      allowBoth: true,
      isPrimeModule: false
    }
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: Subscribe,
    props: true,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      isPrimeModule: false
    }
  },
  {
    path: '/subscribeRegistered',
    name: 'subscribeRegistered',
    component: SubscribeRegistered,
    props: true,
    meta: { isPrimeModule: false }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      isPrimeModule: false
    }
  },
  {
    path: '/password/email',
    name: 'forgot',
    component: Forgot,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      isPrimeModule: false
    }
  },
  {
    path: '/password/reset/:token',
    name: 'reset',
    component: Reset,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      isPrimeModule: false
    }
  },
  {
    alias: '/',
    path: '/checkin',
    name: 'checkin',
    meta: { 
      layout: 'app',
      isPrimeModule: false
    },
    component: CheckIn,
  },
  {
    path: '/checkin/info',
    name: 'info',
    meta: { 
      layout: 'app',
      isPrimeModule: false
    },
    component: Info,
    props: true,
  },
  {
    path: '/door',
    name: 'door',
    meta: { 
      layout: 'app',
      isPrimeModule: false
    },
    component: Door,
  },
  {
    path: '/door/info',
    name: 'info-door',
    meta: { 
      layout: 'app',
      isPrimeModule: false
    },
    component: InfoDoor,
    props: true,
  },
  {
    path: '/settings/pin',
    name: 'settings-pin',
    meta: { 
      layout: 'app',
      isPrimeModule: false
    },
    component: AuthorizationPIN,
  },
  {
    path: '/settings/information',
    name: 'information',
    meta: { layout: 'app' },
    component: Information,
  },
  // {
  //   path: '/settings/subscription',
  //   name: 'subscription',
  //   meta: { 
  //     layout: 'app',
  //     isPrimeModule: false
  //   },
  //   component: Subscription,
  // },
  {
    path: '/settings/notifications',
    name: 'notifications',
    meta: { 
      layout: 'app',
      isPrimeModule: false
    },
    component: Notifications,
  },
  {
    path: '/settings/documents',
    name: 'documents',
    meta: { 
      layout: 'app',
      isPrimeModule: false
    },
    component: Documents,
  },
  {
    path: '/loaded',
    name: 'loaded',
    meta: { 
      layout: 'app',
      isPrimeModule: false
    },
    component: Loaded,
  },
  {
    path: '/unloaded',
    name: 'unloaded',
    meta: { 
      layout: 'app',
      isPrimeModule: false
    },
    component: Unloaded,
  },
  {
    path: '/pickup/info',
    name: 'pickup-info',
    meta: { 
      layout: 'app',
      isPrimeModule: false
    },
    component: LoadInfo,
    props: true,
  },
  {
    path: '/messages',
    name: 'messages',
    meta: { 
      layout: 'app',
      isPrimeModule: false
    },
    component: Messages,
  },
];
