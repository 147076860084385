import * as types from '@/store/types';
import router from '@/router';
import TokenService from '@/services/TokenService';

export default {
  async fetchUser({ commit }) {
    try {
      const { data: { data } } = await axios.get('/profile');
      commit(types.FETCH_USER_SUCCESS, data);

      return data;
    } catch (e) {
      commit(types.FETCH_USER_FAILURE);
      return null;
    }
  },

  async login({ dispatch, commit }, credentials) {
    try {
      commit(types.TOGGLE_SPINNER);

      const { data } = await axios.post('/login', {
        restrictArea: true,
        ...credentials,
      });

      TokenService.setToken(data.access_token);
      await dispatch('fetchUser');
      await TokenService.setUserData(data);

      commit(types.SAVE_TOKEN, data.access_token);

      const currentUser = data ? data : null;

      // Redirect the user to the page he first tried to visit or to the home view
      router.push(router.history.current.query.redirect || '/checkin');

      // if (!currentUser || !currentUser.is_premium || currentUser.is_premium === 0) {
      //   // non prime user redirect on subscribe settings screen
      //   router.push({
      //     path: "/settings/subscription"
      //   });
      // } else {
      //   // Redirect the user to the page he first tried to visit or to the home view
      //   router.push(router.history.current.query.redirect || '/checkin');
      // }

      return true;
    } catch (e) {
      const message = e.response.data.error;
      Vue.toasted.error(message);

      return false;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async register({ commit }, req) {
    try {
      commit(types.TOGGLE_SPINNER);

      const { data: { data } } = await axios.post('/register', {
        role: 'warehouse',
        avatar: window.location.origin + '/images/default-avatar.png',
        ...req
      });

      commit(types.REGISTER_SUCCESS, data);
      return data;
    } catch (e) {
      throw e;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async forgot({ commit }, email) {
    try {
      commit(types.TOGGLE_SPINNER);

      await axios.post('/forgot-password', email);

      return true;
    } catch (e) {
      throw e;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async reset({ commit }, credentials) {
    try {
      commit(types.TOGGLE_SPINNER);

      await axios.post('/reset-password', credentials);

      return true;
    } catch (e) {
      throw e;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async update({ commit }, profile) {
    try {
      commit(types.TOGGLE_SPINNER);

      const { data: { data } } = await axios.post('/warehouse/profile', profile);
      commit(types.FETCH_USER_SUCCESS, data);

      Vue.toasted.success('Profile updated!');
    } catch (e) {
      let firstKey = Object.keys(e.response.data.errors);
      const message = e.response.data.errors[firstKey][0];
      Vue.toasted.error(message);
      
      return false;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async logout({ commit }) {
    try {
      await axios.post('/setDeviceToken', { device_token: null });
      await axios.post('/logout');
    } catch (e) {
      throw e;
    } finally {
      TokenService.removeToken();
      commit(types.LOGOUT);
    }
  },
};
