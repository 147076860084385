import * as types from '@/store/types';

export default {
  async fetch({ commit }) {
    try {
      commit(types.TOGGLE_SPINNER);
      const { data } = await axios.get('/warehouse/unloaded');
      commit(types.FETCH_UNLOADED_SUCCESS, data);
    } catch (e) {
      commit(types.FETCH_UNLOADED_FAILURE);
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async fetchWeek({ commit }) {
    try {
      commit(types.TOGGLE_SPINNER);
      const { data } = await axios.get('/warehouse/unloaded?date=week');
      commit(types.FETCH_UNLOADED_SUCCESS, data);
    } catch (e) {
      commit(types.FETCH_UNLOADED_FAILURE);
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async fetchToday({ commit }) {
    try {
      commit(types.TOGGLE_SPINNER);
      const { data } = await axios.get('/warehouse/unloaded?date=today');
      commit(types.FETCH_UNLOADED_SUCCESS, data);
    } catch (e) {
      commit(types.FETCH_UNLOADED_FAILURE);
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async fetchMonth({ commit }) {
    try {
      commit(types.TOGGLE_SPINNER);
      const { data } = await axios.get('/warehouse/unloaded?date=month');
      commit(types.FETCH_UNLOADED_SUCCESS, data);
    } catch (e) {
      commit(types.FETCH_UNLOADED_FAILURE);
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  }
};
