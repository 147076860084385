<template>
  <transition name="page" mode="out-in">
    <div class="layout default-layout">
      <transition name="page" mode="out-in">
        <router-view />
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'default',
}
</script>
