<template>
  <header class="main-header" :class="{ collapsed: showBackButton }">
    <router-link
      to="/"
      tag="button"
      class="info show-icon"
      v-if="showBackButton"
    >
      <i class="material-icons">keyboard_arrow_left</i>
      BACK
    </router-link>
    <router-link
      to="/plan"
      tag="button"
      class="info show-icon"
      v-if="showBackToPlanButton"
    >
      <i class="material-icons">keyboard_arrow_left</i>
      BACK
    </router-link>
    <button
      tag="button"
      class="info show-icon"
      v-if="showBackToStepOneButton"
      @click="goBackMethod"
    >
      <i class="material-icons">keyboard_arrow_left</i>
      BACK
    </button>
    <img src="/images/logo.png" />
  </header>
</template>

<script>
// import { eventBus } from "../main";

export default {
  name: 'main-header',
  created() {
  },
  methods: {
    goBackMethod(){
      eventBus.$emit("goBackPlease", true);
    }
  },
  props: {
    showBackButton: {
      type: Boolean,
    },
    showBackToPlanButton: {
      type: Boolean
    },
    showBackToStepOneButton: {
      type: Boolean
    }
  },
}
</script>
