import Cookies from 'js-cookie';

const TOKEN_KEY = 'token';
const USER_DETAILS_KEY = 'user_data';
const EXPIRATION_DAYS = 365;

export default {
  getToken() {
    return Cookies.get(TOKEN_KEY);
  },

  /**
   * Set user data in cookies.
   *
   * @param {object} token
   */
  async setUserData(user_data) {
    await Cookies.set(USER_DETAILS_KEY, btoa(JSON.stringify(user_data)), { expires: EXPIRATION_DAYS });
  },

  /**
   * Get user data from cookie.
   *
   * @returns {object|null}
   */
  getUserData() {
    const data = Cookies.get(USER_DETAILS_KEY);
    return data ? JSON.parse(atob(data)) : null;
  },


  setToken(token) {
    Cookies.set(TOKEN_KEY, token, EXPIRATION_DAYS);
  },

  removeToken() {
    Cookies.remove(TOKEN_KEY);
    Cookies.remove(USER_DETAILS_KEY);
  }
};
