<template>
  <component :is="layout"></component>
</template>

<script>
//import Vue from "vue";
import { mapState } from "vuex";
import firebase from "firebase/app";

import "@firebase/firestore";
const $ = require("jquery");

window.$ = $;

firebase.initializeApp({
  apiKey: "AIzaSyCB70qkVrSCkbKOGK3QKzKiT101u42rl3Y",
  databaseURL: "https://dd-truckerd-in.firebaseio.com",
  projectId: "dd-truckerd-in",
  storageBucket: "dd-truckerd-in.appspot.com",
  messagingSenderId: "103524202197"
});

export default {
  computed: {
    defaultLayout() {
      return "default";
    },
    layout() {
      return `${this.$route.meta.layout || this.defaultLayout}-layout`;
    }
  }
};
</script> 
