import * as types from '@/store/types';

export default {
  [types.TOGGLE_SPINNER] (state) {
    state.isLoading = !state.isLoading;
  },

  [types.FETCH_PICKUPS_SUCCESS] (state, pickups) {
    state.pickups = pickups;
    state.allPickups = pickups;
  },

  [types.FETCH_PICKUPS_FAILURE] (state) {
    state.pickups = [];
    state.allPickups = [];
  },

  [types.TOGGLE_MOST_RECENT_PICKUP] (state) {
    const all = [...state.allPickups];

    // Toggle showing
    state.showMostRecent = !state.showMostRecent;

    if (state.showMostRecent) {
      state.pickups = all.splice(0, 1);
    } else {
      state.pickups = all;
    }
  },
};
