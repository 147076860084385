<template>
  <div class="nav-side">
    <ul>
      <router-link to="/settings/pin" tag="li">
        AUTHORIZATION PIN
      </router-link>
      <router-link to="/settings/information" tag="li">
        INFORMATION
      </router-link>
      <!-- <router-link to="/settings/subscription" tag="li">
        SUBSCRIPTION
      </router-link> -->
      <router-link to="/settings/notifications" tag="li">
        NOTIFICATIONS
      </router-link>
      <router-link to="/settings/documents" tag="li">
        DOCUMENTS
      </router-link>
      <li v-on:click="showLogoutModal = true"><a href="#">LOG OUT</a></li>
    </ul>

    <modal
      v-if="showLogoutModal"
      v-on:close="showLogoutModal = false">
      <div class="text-center pb-5">
        Are you sure you want to log out?
      </div>
      <button :disabled="isLoading" @click="submitLogout">Confirm</button>
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'nav-side',
  data: () => ({
    showLogoutModal: false,
    isLoading: false,
  }),
  methods: {
    ...mapActions('auth', ['logout']),

    async submitLogout() {
      this.isLoading = true;

      // Log out the user.
      await this.logout();

      // Redirect to login.
      this.$router.replace({ name: 'login' });
    },
  },
}
</script>
