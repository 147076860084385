import * as types from '@/store/types';
import axios from 'axios';

export default {
  async fetch({ commit }, mode) {
    try {
      commit(types.TOGGLE_SPINNER);
      const { data } = await axios.get('/warehouse/door', { params: { mode } });
      commit(types.FETCH_DOOR_SUCCESS, data);
    } catch (e) {
      commit(types.FETCH_DOOR_FAILURE);
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async proofDelivery({ commit }, { pickup, data }) {
    try {
      commit(types.TOGGLE_SPINNER);
      await axios.patch(`/warehouse/pickups/${pickup.id}/proof`, data);
    } catch (e) {
      throw e;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  async underProtest({ commit }, pickup ) {
    try {
      commit(types.TOGGLE_SPINNER);
      await axios.patch(`/warehouse/pickups/${pickup.id}/underProtest`);
    } catch (e) {
      throw e;
    } finally {
      commit(types.TOGGLE_SPINNER);
    }
  },

  toggleMostRecent({ commit }) {
    commit(types.TOGGLE_MOST_RECENT_DOOR);
  }
};
