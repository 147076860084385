<template>
  <header class="nav-top">
    <router-link to="/checkin">
      <img src="/images/logo.png" />
    </router-link>

    <div class="divisor ml-5 -mb-1"></div>

    <t-dropdown :options="['SHIPPING', 'RECEIVING']" :value="mode" @change="handleMode" />

    <div class="divisor -mb-1"></div>

    <div class="links">
      <router-link to="/checkin" tag="div">CHECK IN</router-link>
      <router-link to="/door" tag="div">@DOOR</router-link>
      <router-link to="/loaded" tag="div" v-if="mode === 'SHIPPING'">LOADED</router-link>
      <router-link to="/unloaded" tag="div" v-else>UNLOADED</router-link>
      <router-link to="/messages" tag="div">
        MESSAGES
        <p
          v-if="route != 'messages' && unreadMessage.length > 0"
          class="unread-count"
        >{{unreadMessage.length}}</p>
      </router-link>
    </div>
    <div class="divisor ml-auto -mb-1"></div>
    <div class="t-menu" @click="menuActive = !menuActive">
      <div
        class="t-menu-activator uppercase"
        :class="{ 'font-bold text-red-500': inSettings }"
        aria-haspopup="true"
      >
        {{ user.name }}
        <i class="material-icons">arrow_drop_down</i>
      </div>
      <ul class="t-menu-dropdown" aria-label="submenu" :class="{ active: menuActive }">
        <router-link to="/settings/pin" tag="li">AUTHORIZATION PIN</router-link>
        <router-link to="/settings/information" tag="li">INFORMATION</router-link>
        <!-- <router-link to="/settings/subscription" tag="li">SUBSCRIPTION</router-link> -->
        <router-link to="/settings/notifications" tag="li">NOTIFICATIONS</router-link>
        <router-link to="/settings/documents" tag="li">DOCUMENTS</router-link>
        <li @click="showLogoutModal = true">LOG OUT</li>
      </ul>
    </div>

    <modal v-if="showLogoutModal" v-on:close="showLogoutModal = false">
      <div class="text-center pb-5">Are you sure you want to log out?</div>
      <button :disabled="isLoading" @click="submitLogout">Confirm</button>
    </modal>
  </header>
</template>

<script>
import { mapActions, mapState } from "vuex";
import firebase from "@firebase/app";
import "@firebase/firestore";
import "@firebase/messaging";

const db = firebase.firestore();

if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();
  //listen for firebase notifications when window is active
  messaging.onMessage(payload => {
    Vue.toasted.info(payload.notification.body);
  });
}

export default {
  name: "main-header",

  props: {
    showBackButton: {
      type: Boolean
    }
  },

  data: () => ({
    menuActive: false,
    inSettings: false,
    showLogoutModal: false,
    isLoading: false,
    conversationSelected: null,
    userData: {},
    unreadMessage: [],
    route: ""
  }),

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("layout", ["mode"])
  },

  watch: {
    $route(to, from) {
      this.route = this.$router.currentRoute.name;
      if (this.route == "messages") this.unreadMessage = [];
      this.inSettings = to.path.startsWith("/settings");
      if (to.name === "loaded" || to.name === "unloaded") {
        this.setMode(to.name === "unloaded" ? "RECEIVING" : "SHIPPING");
      }
    }
  },

  methods: {
    ...mapActions("auth", ["fetchUser", "logout"]),
    ...mapActions("layout", ["setMode"]),

    async submitLogout() {
      this.isLoading = true;

      // Log out the user.
      await this.logout();

      // Redirect to login.
      this.$router.replace({ name: "login" });
    },

    handleMode(mode) {
      // this.$router.push('/').catch(error => error);
      this.$router.push("/");
      this.setMode(mode);
    },

    getUnreadCounts() {
      db.collection("chats")
        .where("receiver.id", "==", this.userData.id)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (!this.unreadMessage.some(message => message.id === doc.id)) {
              let data = doc.data();

              if (data.read == false) {
                this.unreadMessage.push({
                  id: doc.id,
                  ...data
                });
              }
            }
          });
        });
      console.log("array", this.unreadMessage);
    }
  },

  async created() {
    // Get user profile
    const {
      data: { data }
    } = await axios.get("/profile");
    this.userData = data;

    // Listen messages as receiver
    this.getUnreadCounts();

    this.route = this.$router.currentRoute.name;
    this.inSettings = this.$router.currentRoute.path.startsWith("/settings");
    this.setMode(
      this.$router.currentRoute.name === "unloaded" ? "RECEIVING" : "SHIPPING"
    );

    await this.fetchUser();
  }
};
</script>

<style>
.unread-count {
  border-radius: 50px;
  background-color: #b43232;
  color: white;
  min-width: 30px;
  height: 30px;
  padding: 0 5px;
  line-height: 30px;
  text-align: center;
  margin-left: 10px;
}
</style>