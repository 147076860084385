import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import TokenService from '@/services/TokenService';

const state = {
  user: {},
  registered: {},
  isLoading: false,
  token: TokenService.getToken(),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
