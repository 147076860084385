<template>
  <transition name="page" mode="out-in">
    <div class="layout app-layout">
      <nav-top></nav-top>
      <div class="flex flex-grow">
        <nav-side v-if="showSideNav"></nav-side>
        <transition :name="showSideNav ? 'page' : 'slide-fade'" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/messaging';

export default {
  name: 'app',
  data: () => ({
    showSideNav: false,
  }),
  computed: mapGetters('auth', ['token']),
  watch: {
    $route(to, from) {
      this.showSideNav = to.path.startsWith('/settings');
    }
  },
  async created() {
    this.showSideNav = this.$router.currentRoute.path.startsWith('/settings');
    if (firebase.messaging.isSupported()) {
      await this.askForPermissionToReceiveNotifications();
    }
  },
  methods: {
    async askForPermissionToReceiveNotifications() {
      const messaging = firebase.messaging();

      // Request permission
      await messaging.requestPermission();

      // Update token
      const token = await messaging.getToken();
      await this.updateDeviceToken(token);

      // Listen token changes
      messaging.onTokenRefresh(async () => {
        const token = await messaging.getToken();
        await this.updateDeviceToken(token);
      });
    },

    async updateDeviceToken(device_token) {
      if (device_token) {
        await axios.post('/setDeviceToken', { device_token });
      }
    },
  },
}
</script>
